import '../../styles/pages/spring-summer-2021/building-a-brotherhood.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import ReactFullpage from '@fullpage/react-fullpage';
import Icon from "../../components/icons"
import YouTube from 'react-youtube';
import { PieChart } from 'react-minimal-pie-chart';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';

import socialBanner from "../../images/spring-summer-2021/social-banners/building-a-brotherhood.jpg";


import mehtaabAndAzaadBrar from '../../images/spring-summer-2021/building-a-brotherhood/mehtaab-and-azaad-brar.jpg';
import mehtaabBrar from '../../images/spring-summer-2021/building-a-brotherhood/mehtaab-brar.jpg';
import azaadBrar from '../../images/spring-summer-2021/building-a-brotherhood/azaad-brar.jpg';
import potential from '../../images/spring-summer-2021/building-a-brotherhood/potential.jpg';
import vincentHarris from '../../images/spring-summer-2021/building-a-brotherhood/vincent-harris.jpg';
import empoweringBlur from '../../images/spring-summer-2021/building-a-brotherhood/empowering-blur.jpg';
import empowering from '../../images/spring-summer-2021/building-a-brotherhood/empowering.jpg';

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../scripts/fullpage.scrollHorizontally.min.js');
};

export default class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
  }

  render() {
    var pageTitle = 'Building a Brotherhood';
    var pageDesc = 'Through the Male Success Initiative-Fullerton, two brothers found academic and emotional support to prepare for careers in medicine.';
    var slug = 'building-a-brotherhood';

    return (
      <Layout headerStyle="white" headerHide={this.state.headerHide} locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

        <ReactFullpage
          id="story-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav'}
          loopBottom={true}
          navigation={false}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          responsiveWidth={'769'}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

          }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section hero-section">
                  <TextPDF slug={slug} />
                  <div className="hero-text">
                    <h1 className="animated fadeInDown slower">{pageTitle}</h1>
                    <button className="button watch-button animated fadeInUp slower" onClick={() => fullpageApi.moveSectionDown()}>Read Story <Icon name="arrow-right" alt="Right arrow" class="sm" /></button>
                  </div>

                  {/* <button className="button read-button" onClick={() => fullpageApi.moveSectionDown()}>Read Story <Icon name="arrow-right" alt="" class="sm" /></button> */}

                  <video id="heroVideo" data-autoplay autoPlay="autoplay" loop muted playsInline>
                    <source src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/building-a-brotherhood-loop.webm" type="video/webm; codecs=vp9,vorbis" />
                    <source src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/building-a-brotherhood-loop.mp4" type="video/mp4" />
                    <track kind="captions" srcLang="en" label="English" src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/building-a-brotherhood-loop.vtt" />
                  </video>

                </div>
                <div className="section fp-auto-height-responsive" style={{backgroundImage: `url(${mehtaabAndAzaadBrar})`}}>
                  <div className="caption">Azaad and Mehtaab Brar</div>
                  <div className="text right">

                    <span className="sub-title">{pageDesc}</span>
                    <hr />
                    <span className="byline">By Debra Cano Ramos, Photos by Matt Gush</span>
  
                    <p><span className="intro-text">Blood brothers Mehtaab and Azaad Brar belong to a brotherhood</span> at Cal State Fullerton that is empowering them to achieve academic success, lift each other up and become leaders.</p>
  
                    <p>The brothers, both biological science majors who aspire to become medical doctors, are scholars in the Male Success Initiative-Fullerton. </p>
  
                    <p>With the motto “Where Undergraduate Men of Color Achieve the Fullness of Their Potential” and mantra of “I Am a Brother,” the initiative offers students of color programming, resources and a network of peer and faculty mentors to support their journey through higher education. </p>
  
                    <p>“The Male Success Initiative has given me a support system outside of my classes,” says Mehtaab, who will be part of the inaugural class of scholars graduating this year. “To have such a motivated group of men around you, makes you want to be the best version of yourself.” </p>
  
                    <p>Azaad followed in his brother’s footsteps when he enrolled in the university as a freshman. He wanted to become involved in the Male Success Initiative after witnessing how Mehtaab formed bonds with the other students — who call each other “brothers” — and discovered a community of like-minded peers.</p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>
                  </div>

                </div>
                <div className="section fp-auto-height-responsive" style={{backgroundImage: `url(${mehtaabBrar})`}}>
                  <div className="text">
                    <h2>A Family on Campus</h2>
                    <p>Mehtaab and Azaad, who are a year apart, hail from Fresno, where their parents instilled in them the importance of higher education and their Indian American heritage. The siblings faced racism in their youth and wanted a college campus where they would feel safe, thrive, make new friends and reach their academic goals.</p>

                    <blockquote>
                      <p>“To have such a motivated group of men around you, makes you want to be the best version of yourself.”</p>
                      <cite><strong>- Mehtaab Brar</strong>, biological science major</cite>
                    </blockquote>

                    <p>The brothers sought out Cal State Fullerton because of its diverse population, science program and linkage to Lake Erie College of Osteopathic Medicine in Pennsylvania, where they hope to attend medical school. Mehtaab is an aspiring plastic surgeon. Azaad is planning a career either in neurology or dermatology.</p>

                    <p>“I have always loved to help people and improve their quality of life, whether it was helping seniors at a nursing home or learning about their life over Zoom,” Mehtaab says.</p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive" style={{backgroundImage: `url(${azaadBrar})`}}>
                  <div className="text right">

                    <p>In college, the pair faced another challenge. Their paternal grandfather, living with Alzheimer’s disease, suffered a stroke and later died. Mehtaab’s grades fell while taking rigorous science courses and caring for his ailing grandfather: “This was probably the hardest time in my college career.”</p>

                    <p>Mehtaab has since worked hard to bring up his GPA to apply for medical school and credits the support of the Male Success Initiative for making it possible. “The Male Success Initiative has helped me stay on track to graduate in four years,” Mehtaab shares. </p>

                    <p>“The brotherhood also has allowed me to be vulnerable — to go outside my cultural norms and show my emotions. Being vulnerable as a man is sometimes hard, but the mantra ‘I Am a Brother’ means a shoulder to lean on when times are rough and someone to celebrate your victories. It means we have a bond that no one can break.”</p>

                    <p>Azaad, a junior and an “A” student, adds that this emotional and academic support are the main reasons so many students succeed. “The Male Success Initiative provides us with a family on campus and people you can always rely on,” he says.</p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive empowering-container" style={{backgroundImage: `url(${empoweringBlur})`}}>
                  <div className="image-left" style={{backgroundImage: `url(${empowering})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${empowering})`}} />
                  <div className="text empowering">
                    <h2>Empowering Men of Color</h2>

                    <div className="videos float_right">
                      <figure>
                        <YouTube
                          className="embed-item"
                          containerClassName="embed-container"
                          videoId="ElKZ-M-npcs"
                        />
                        <figcaption>Video courtesy of Tyree Vance and Nate Brown</figcaption>
                      </figure>
  
                      <figure>
                        <YouTube
                          className="embed-item"
                          containerClassName="embed-container"
                          videoId="r-4PIbK_0_Y"
                        />
                        <figcaption>Video courtesy of Sedgwick “Sed” McCray</figcaption>
                      </figure>
                    </div>

                    <p>In 2013, a pilot effort — the African American Male Leadership Institute — was launched on campus to support Black men. Studies show their college graduation and retention rates are significantly lower than their peers. </p>

                    <p>Under the leadership of director Vincent T. Harris, the initiative has since evolved into the Male Success Initiative, supporting students of color, such as African American, Hispanic/Latino, Asian American/Pacific Islander, Native American, as well as multiracial students and people of trans experience.</p>

                    <p>To help alleviate financial barriers, $3,000 scholarships are awarded to 15 first-time freshmen each year. Individual, corporate and foundation philanthropic donations — including from the <a href="http://news.fullerton.edu/2021/01/empowering-young-men-of-color-to-stand-up-for-social-justice/" target="_blank" rel="noopener noreferrer">Joseph Drown Foundation</a> and <a href="https://ecmcfoundation.org/" target="_blank" rel="noopener noreferrer">ECMC Foundation</a> —  support programming and scholarships.</p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive" style={{backgroundImage: `url(${potential})`}}>
                  <div className="text">

                    <h2>Pillars of Potential</h2>
                    <p>Together, students participate in programming rooted in four pillars: academic support programs and tailored mentoring; leadership opportunities; career planning; and explorations of such topics as gender, masculinity and belonging.  </p>

                    <p>“Our programming focuses on intrusive holistic support to make sure that what we provide is intentionally geared toward students’ overall success in all aspects of their lives,” says George W. Parker IV, senior program coordinator.</p>

                    <p>Maintaining a high GPA and staying on course to graduate in four years are an emphasis of the Male Success Initiative. The average GPA is 3.25 for the fall 2020 class — the highest GPA for the initiative to date.</p>

                    <p>In 2019, the university opened the Male Success Initiative-Fullerton Center on the second floor of Gordon Hall. The building’s namesake is the university’s long-serving African American president, the late Milton A. Gordon. The center is the result of faculty, staff and former administrators who saw the need and carried the torch to open a campus center for students of color, Harris notes.</p>

                    <p>“To describe brotherhood, it’s not just words: Our brothers demonstrate brotherhood through their actions. Brothers can turn to one another for support, perspective, insight or just to talk. This makes a world of difference,” he adds.</p>

                    <p>For students like Mehtaab, nothing could be truer: “No matter where life takes me, I will always have the support of my brothers.” <span className="end-mark" /></p>

                    

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section fp-auto-height-responsive about-msi">
                  <div className="text">

                    <h2 className="center">Male Success Initiative-Fullerton</h2>

                    <div className="stats-row">
                      <div>

                        <ul className="stats center">
                          <li>Motto<br /><strong>“Where Undergraduate Men of Color Achieve the Fullness of Their Potential”</strong></li>
                          <li>Mantra<br /><strong>“I Am a Brother”</strong></li>
                          <li><strong>80 students</strong><br />currently participate in MSI<br /><small>(spring 2020)</small></li>
                          <li><strong>3.25</strong> average GPA for MSI students<br /><small>(fall 2020)</small></li>
                          <li><strong>40%</strong> of MSI students made the dean’s list<br /><small>(fall 2020)</small> </li>
                        </ul> 

                      </div>
                      <div>

                        <PieChart
                          animate 
                          style={{ height: '250px' }}
                          // label={({ dataEntry }) => dataEntry.title}
                          // labelStyle={{
                          //   fontSize: '4px',
                          //   fontFamily: 'sans-serif',
                          //   fill: '#ffffff'
                          // }}
                          data={[
                            { title: 'Hispanic/Latino', value: 48, color: '#154B66' },
                            { title: 'Black/African American', value: 29, color: '#1F7098' },
                            { title: 'Asian/Pacific Islander', value: 20, color: '#2A95CB' },
                            { title: 'Two or more ethnicities', value: 3, color: '#56AFDC' },
                          ]}
                        />

                        <p>Students by ethnicity <small>(fall 2020)</small>:</p>
                        <ul className="ethnicity">
                          <li>Hispanic/Latino <strong>48%</strong></li>
                          <li>Black/African American <strong>29%</strong></li>
                          <li>Asian/Pacific Islander <strong>20%</strong></li>
                          <li>Two or more ethnicities <strong>3%</strong></li>
                        </ul>

                      </div>
                    </div>

                    <p className="center"><small>Source: Male Success Initiative-Fullerton/Student Affairs</small></p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section fp-auto-height-responsive meet-the-director" style={{backgroundImage: `url(${vincentHarris})`}}>
                  <div className="text right">

                    <h2>Meet the Director</h2>
                    
                    <p>As director of the university’s Male Success Initiative-Fullerton, Vincent T. Harris is passionate about increasing access and retention for men of color through academic guidance, student leadership development and social engagement. His professional and research track record restores and explores the lived experiences of undergraduate and graduate students as it relates to the intersection of gender, ethnicity, sexual orientation and reimagining acceptance.</p>

                    <p>Harris, a Birmingham, Alabama, native is the first in his family to earn a doctorate. He holds a doctorate in educational leadership and research from Louisiana State University, a master’s degree in college student personnel from Ohio University and a bachelor’s degree in mass communication from Auburn University. He completed a postdoctoral fellowship in higher education at Harvard University.</p>

                    <p className="learn-more">
                    Support Male Success Initiative-Fullerton<br /><a href="https://campaign.fullerton.edu/students" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu/students</a>
                    </p>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section more-stories-wrap fp-auto-height fp-auto-height-responsive">
                  <MoreStories slug={slug} />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size={30} frontColor="#dc8218" backColor="#00aeff" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

      </Layout>
    );
  }
} 